<template>
<ValidationObserver ref="observer" v-slot="{ validate  }">
  <b-form @submit.stop.prevent="validate().then(CriarPrescritor)">
  <div class="w-full flex flex-col justify-between">
    <section class="-mt-4">
      <b-tabs class="w-full">
        <b-tab title="Geral">
          <GeneralPrescritorInformation
            class="mt-4"
            v-model="viewModel"
          />
        </b-tab>
        <b-tab title="Complemento">
          <SupplementaryInformationPrescritor
            class="mt-4"
            v-model="viewModel"
          />
        </b-tab>
      </b-tabs>
    </section>
    <div class="flex">
      <span class="text-red-600">Os campos com * são de preenchimento obrigatório</span>
    </div>
    <footer class="w-full flex items-center justify-start gap-5 my-10">
      <Button
        class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
        buttonText="Confirmar"
        type="submit"
      />
      <Button
        class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
        buttonText="Cancelar"
        :onClick="redirectUrl"
      />
    </footer>
    <Modal
      v-if="modalIsOpen"
      :modalIsOpen.sync="modalIsOpen"
      :closeModal="closeModal"
      :modalText="$t('commons.modalTextCreate')"
    />
    <Modal
      v-if="modalError"
      :modalIsOpen.sync="modalError"
      :closeModal="closeModal"
      :modalText="$t('commons.modalErrorCreate')"
    />
    <Modal
      v-if="modalErroSalvar"
      :modalIsOpen.sync="modalErroSalvar"
      :closeModal="closeModal"
      :modalText="$t('commons.modalErroSalvar')"
    />
  </div>
</b-form>
</ValidationObserver>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";

import GeneralPrescritorInformation from "./GeneralPrescritorInformation";
import SupplementaryInformationPrescritor from "./SupplementaryInformationPrescritor";
import Button from "@core/components/commons/button/Button";
import Modal from "@core/components/commons/modal/Modal";
import { BForm } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";

export default {
  name: "ManageSuppliersCreate",
  components: {
    BTab,
    Modal,
    BTabs,
    BForm,
    Button,
    ValidationObserver,
    GeneralPrescritorInformation,
    SupplementaryInformationPrescritor,
  },
  data() {
    return {
      modalIsOpen: false,
      modalError: false,
      modalErroSalvar: false,
      modalErrorMessage: null,
      viewModel: {
        Name: null,
        TipoCr: null,
        CpfCnpj: null,
        DataNascimento: null,
        Ativo: false,
        NumeroCR: null,
        UfCr:null,
        Secretaria: null,
        NomeRotulo: null,
        Email: null,
        VisitadorId: null,
        ObservacaoVendas: null,
        RegistroMapa: null,
        Proximidade: null,
        CEDH: false,
        endereco: [
          {
            CEP: null,
            Endereco: null,
            BairroId: null,
            CidadeId: null,
            EstadoId: null,
            Numero: null,
            Complemento:null,
            IsResidencial: false,
          },
          {
            CEP: null,
            Endereco: null,
            BairroId: null,
            CidadeId: null,
            EstadoId: null,
            Complemento:null,
            Numero: null,
            IsResidencial: true,
          },
        ],
        Telefone: [
          {
            DDD: null,
            Telefone: null,
            IsResidencial: false,
          },
          {
            DDD: null,
            Telefone: null,
            IsResidencial: false,
          },
          {
            DDD: null,
            Telefone: null,
            IsResidencial: true,
          },
        ],
        Especialidade: [],
      },
    };
  },
  methods: {
    redirectUrl() {
      return this.$router.push("/prescritor");
    },
    openModal() {
      this.modalIsOpen = true;
    },
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalError = false;
        this.modalErroSalvar = false;
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    async CriarPrescritor () {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.modalError = true;
        return
      }
        this.RemoverMascaras();
        this.$http({
          url: "/prescritor/novo",
          data: this.viewModel,
          method: "POST",
        })
          .then(() => {
            this.modalIsOpen = true;
            setTimeout(() => this.$router.push({ name: "prescritor" }), 3000);
          })
          .catch(() => (this.modalErroSalvar = true));
    },
    RemoverMascaras () {
      this.viewModel.Telefone = this.viewModel.Telefone?.filter((item) => item.DDD != null && item.DDD != "" && item.Telefone != null &&item.Telefone != "" );
      if (this.viewModel.DataNascimento == "") this.viewModel.DataNascimento == null;
      if (this.viewModel.Telefone?.length == 0) this.viewModel.Telefone = null;
      if (this.viewModel.endereco.CEP?.length == 0) this.viewModel.endereco.CEP = null;
      if (this.viewModel.Especialidade?.length == 0) this.viewModel.Especialidade = null;

      for (let index = 0; index < this.viewModel.Telefone?.length; index++) {
        this.viewModel.Telefone[index].DDD = this.viewModel.Telefone[
          index
        ].DDD?.replace(/[^0-9,]*/g, "");
        this.viewModel.Telefone[index].Telefone = this.viewModel.Telefone[
          index
        ].Telefone?.replace(/[^0-9,]*/g, "");
      }
      for (let index = 0; index < this.viewModel.endereco?.length; index++) {
        this.viewModel.endereco[index].CEP = this.viewModel.endereco[
          index
        ].CEP?.replace(/[^0-9,]*/g, "");
      }
      this.viewModel.CpfCnpj = this.viewModel.CpfCnpj?.replace(/[^0-9,]*/g, "");
    },
  },
  
};
</script>

<style></style>
