<template>
  <section>
    <b-form>
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8 border-solid border-b border-gray-300 pb-2">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <input-default
                v-model="value.Email"
                :rules="{ email: true }"
                max="60"
                :label="$t('sectionPrescriber.personalData.email')"
              />
            </div>
          </div>
        </div>

        <div class="col-span-8">
          <div
            class="grid grid-cols-12 border-solid border-b border-gray-300 pb-2 gap-4"
          >
            <div class="col-span-12 xl:col-span-3">
              <input-default
                v-model="value.endereco[1].CEP"
                :label="$t('sectionPrescriber.addressData.zipCode')"
                mask="#####-###"
                max="9"
                :rules="{ min: 9 }"
                :hasIcon="true"
              />
            </div>
            <div class="col-span-12 xl:col-span-7">
              <input-default
                v-model="value.endereco[1].Endereco"
                :label="$t('sectionPrescriber.addressData.homeAddress')"
                max="50"
              />
            </div>
            <div class="col-span-12 lg:col-span-2">
              <input-default
                v-model="value.endereco[1].Numero"
                max="7"
                :label="$t('sectionPrescriber.addressData.number')"
                placeholder="Nº"
              />
            </div>
            <div class="col-span-12 lg:col-span-4">
              <Dropdown
                :Items="dropdownItemsEstado"
                v-model="value.endereco[1].EstadoId"
                :valueDefault="$t('sectionPrescriber.addressData.state')"
                :label="$t('sectionPrescriber.addressData.state')"
                @ItemSelectId="DropdownSelected($event, 'estado')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 lg:col-span-4">
              <Dropdown
                :Items="dropdownItemsCidade"
                v-model="value.endereco[1].CidadeId"
                :valueDefault="$t('sectionPrescriber.addressData.city')"
                :label="$t('sectionPrescriber.addressData.city')"
                @ItemSelectId="DropdownSelected($event, 'cidade')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 lg:col-span-4">
              <Dropdown
                :Items="dropdownItemsBairro"
                v-model="value.endereco[1].BairroId"
                :valueDefault="$t('sectionPrescriber.addressData.district')"
                :label="$t('sectionPrescriber.addressData.district')"
                @ItemSelectId="DropdownSelected($event, 'bairro')"
              >
              </Dropdown>
            </div>
            <div class="col-span-12 xl:col-span-7">
              <input-default
                v-model="value.endereco[1].Complemento"
                max="20"
                :label="$t('sectionProviders.addressData.complemento')"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-3">
        <div class="col-span-8">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 xl:col-span-2">
              <input-default
                v-model="value.Telefone[2].DDD"
                :label="$t('sectionPrescriber.contactData.ddd1')"
                max="4"
                mask="(##)"
              />
            </div>
            <div class="col-span-12 xl:col-span-4">
              <input-default
                v-model="value.Telefone[2].Telefone"
                max="9"
                mask="####-####"
                :label="$t('sectionPrescriber.contactData.homePhone')"
              />
            </div>
            <div class="col-span-12 xl:col-span-6">
              <input-default
                max="60"
                v-model="value.Proximidade"
                :label="$t('sectionPrescriber.contactData.proximity')"
              />
            </div>
          </div>
        </div>
        <div class="col-span-8"></div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-8">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <Dropdown
                :Items="dropdownItemsVisitador"
                :valueDefault="
                  $t('sectionPrescriber.complementaryInfo.visitor')
                "
                :label="$t('sectionPrescriber.complementaryInfo.visitor')"
                v-model="value.VisitadorId"
              >
              </Dropdown>
            </div>
            <div class="col-span-12">
              <input-default
                v-model="value.ObservacaoVendas"
                max="200"
                :label="
                  $t('sectionPrescriber.complementaryInfo.observationSale')
                "
              />
            </div>
          </div>
        </div>

        <div class="col-span-8">
          <div class="grid grid-cols-12">
            <div class="col-span-12">
              <input-default
                v-model="value.RegistroMapa"
                max="50"
                :label="$t('sectionPrescriber.complementaryInfo.mapRecord')"
              />
            </div>
          </div>
          <div class="col-span-12">
            <div class="w-full flex items-center">
              <CheckboxDefault label="CEDH" v-model="value.CEDH" />
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </section>
</template>

<script type="module">
import { required } from "@validations";
import InputDefault from "@core/components/commons/inputs/InputDefault";
import CheckboxDefault from "@core/components/commons/checkbox/CheckboxDefault";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";

import { BForm } from "bootstrap-vue";

export default {
  name: "SupplementaryInformationPrescritor",
  components: {
    BForm,
    InputDefault,
    CheckboxDefault,
    Dropdown,
  },
  props: {
    required,
    value: {
     type:Object,
      required:true
    },
  },
  data() {
    return {
      dropdownItemsEstado: [],
      dropdownItemsVisitador: [],
      dropdownItemsBairro: [],
      dropdownItemsCidade: [],
      cep:null
    };
  },
  created() {
    this.ObterSeletorEstado();
    this.ObterSeletorVisitador();
  },
  methods: {
    ObterSeletorBairro(cidadeId) {
      this.$http({
        url: `/bairro/obter-seletor-bairro`,
        params: { idCidade: `${cidadeId}` },

        method: "GET",
      }).then((res) => {
        this.dropdownItemsBairro = res.data;
      });
    },
    ObterSeletorEstado() {
      this.$http({
        url: `/estado/obter-seletor-estados`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsEstado = res.data;
      });
    },
    ObterSeletorCidade(estadoId) {
      this.$http({
        url: `/cidade/obter-seletor-cidades-por-estado/${estadoId}`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsCidade = res.data;
      });
    },
    ObterSeletorVisitador() {
      this.$http({
        url: `/visitador/obter-seletor-visitador`,
        method: "GET",
      }).then((res) => {
        this.dropdownItemsVisitador = res.data;
      });
    },
    DropdownSelected(item, tipo) {
      if (tipo == "estado") {
        this.ObterSeletorCidade(item);
      } else if (tipo == "cidade") {
        this.ObterSeletorBairro(item);
      }
    },
  },
  watch: {
    value: {
      handler (value) {
        if (value.endereco[1].CEP.length == 9 && this.cep != value.endereco[1].CEP) {
          this.cep = value.endereco[1].CEP;
          this.$http({
            url: `/Endereco/cep`,
            params: { CEP: value.endereco[1].CEP },
            method: "POST"
          }).then((res) => {
            if (res.data.ufCadastrado) {
              this.dropdownItemsEstado?.push({ id: res.data.ufId, descricao: res.data.uf })
            }
            this.value.endereco[1].Endereco = res.data.logradouro;
            this.value.endereco[1].EstadoId = res.data.ufId;
            this.value.endereco[1].CidadeId = res.data.cidadeId;
            this.value.endereco[1].BairroId = res.data.bairroId;
          })
        }
      },
      deep: true
    }
  }
};
</script>
